<template lang="">
    <div class="d-flex flex-column position-fixed top-0 start-0 vh-100 w-100 " data-cmp="ProfileMain">
        <AppHeader></AppHeader>
        <section class="flex-fill container-fluid px-0 ">
            <div class="row g-0 mx-0">
                <div class="col-12 d-flex flex-row">
                    <!-- sidebar -->
                    <div class="sidebar position-fixed">
                        <Sidebar></Sidebar>
                    </div>


                    <!-- content area -->
                    <div class="content bg-canvas d-flex flex-column flex-fill">
                        <!-- sub navigation -->
                        <div class="bg-white border-bottom border-start d-flex flex-row navbar-wrapper px-3 py-2.5 --shadow-sm">
                            <div class="align-items-center d-flex">
                                <a @click.prevent="$router.back()" class="btn btn-primary btn-sm font-size-16 px-1 rounded-pill">
                                    <span class="material-icons pull-left">
                                    chevron_left
                                    </span>
                                </a>
                            </div>

                            <div>
                                <nav class="ps-3" style="--bs-breadcrumb-divider: '›';" aria-label="breadcrumb">
                                    <ol class="breadcrumb mb-0 fw-500 font-size-18 lh-lg">
                                        <li class="breadcrumb-item">
                                            <router-link class="text-decoration-none" :to="$root.links?.MY_OPPORTUNITIES">
                                                <span class="pe-2">Opportunities</span>
                                            </router-link>
                                        </li>


                                        <li class="breadcrumb-item">
                                            <router-link 
                                                class="text-decoration-none"
                                                :to="backLink">
                                            {{ opportunity?.title }}
                                            </router-link>
                                        </li>

                                        <li class="breadcrumb-item">
                                            Report
                                        </li>

                                        <li class="breadcrumb-item">
                                            {{ currentSection }}
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>


                        <!-- info area -->
                        <div class="overflow-scroll pb-5 pt-5 px-4 px-lg-4 shadow-inset-md">
                            <div class="form-wrapper mx-auto bg-white rounded-5 p-5 shadow-sm">

                                <div class="position-relative" v-if="$route.params.section == 'swot'">
                                    <div class="bg-primary border border-0 fs-2 mb-5 p-3 rounded-3 rounded-4 text-white" >
                                        <p class="fs-5 text-uppercase--">Business Hypothesis</p>
                                        <h3 class="fw-normal" v-html="hypothesis"></h3>
                                    </div>

                                    <!-- <div class="position-absolute top-0 end-0">PRINT - DOWNLOAD</div> -->
                                    
                                    <div v-html="swot"></div>
                                </div>
                                
                                <div class="position-relative" v-if="$route.params.section == 'tows'">
                                    <div class="alert alert-warning p-5" role="alert">
                                        <h4>Coming soon</h4>
                                        This report is not available yet.
                                    </div>
                                </div>

                                <div class="position-relative" v-if="$route.params.section == 'data'">
                                    <span class="border border-secondary btn btn-light btn-pill btn-sm disabled end-0 fs- m-2 position-absolute py-0 text-muted top-0">READ ONLY</span>
                                    <pre class="border p-2 font-monospace">{{ opportunity }}</pre>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>


</template>
<script>

import Sidebar from "@/views/reports/Sidebar.vue";
import AppHeader from "@/components/AppHeader";
// import NavBar from "@/views/opportunity/NavBar.vue"; 

export default {
    name: "ReportsMain",

    components: {
        AppHeader,
        Sidebar,
        // NavBar
    },

    computed:{
        opportunity() {
            return this.$store.state.current.opportunity;
        }, 

        hypothesis() {
            let output = '...';
            if(this.$store.state.current.opportunity?.content?.hypothesis?.fields?.preview) {
                output = this.$store.state.current.opportunity.content.hypothesis.fields.preview;
            }

            return output;
        },

        /**
         * SWOT states (indicator values from the "analysis" form)
         * 
         */
        states() {
            let states = null;
            if(this.$store.state.current.opportunity?.content?.analysis?.fields) {
                states = {...this.$store.state.current.opportunity?.content?.analysis?.fields};
                delete states.done;
                this.$store.dispatch('generateSWOT', states);
            }
            return states;
        },

        swot() {
            if(this.states == null) {
                return 'loading...';
            } else {
                return this.$store.state.reports?.swot;
            }
        }, 

        backLink() {
            let link = `/edit/opportunities/${this.$route.params?.opportunity}/business/overview`;
            if(this.$route.params.section == 'swot') {
                link = `/edit/opportunities/${this.$route.params?.opportunity}/business/analysis`;
            }
            return link;
        },

        currentSection() {
            let section = this.$route.params?.section.charAt(0).toUpperCase() + this.$route.params?.section.substr(1);
            return section;
        }
    }, 


    methods: {
    },


    created() {
    }, 

    beforeMount() {
        // console.log('beforeMount')
    },

    async mounted() {
        let name = this.$route.params?.opportunity;
        if(name) {
            await this.$store.dispatch('fetchOpportunity', name);
        }
        this.$store.getters.wp.generateSWOTObject(this.states).then(data => console.log(data));
    },
}
</script>


<style scoped>


.sidebar {
   width: 320px; 
}
.content {
   margin-left: 320px; 
   height: calc(100vh - 86px);
}
.form-wrapper {
    max-width: 990px;
    min-width: 670px;
}
.navbar-wrapper {
    padding-top: 9px !important;
    padding-bottom: 9px !important;
}
.rounded-5 {
    border-radius: .5rem!important;
}
</style>